/*
Author: XpeedStudio
Author URI: http://themeforest.net/user/XpeedStudio/portfolio
*/
/* ==========================================================================
   Author's custom styles
   ========================================================================== */
html,
body {
    font-size: 15px;
    color: #565656;
    width: 100%;
    padding: 0;
    margin-left: 0;
    margin-right: 0;
    /*font-family: 'robotolight';*/
    font-weight: 300;
    overflow-x: hidden;
}

img {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-user-drag: none;
    user-drag: none;
}


/*==========================================
PRE LOADER 
==========================================*/

/*.preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fefefe;
    z-index: 99999;
    height: 100%;
    width: 100%;
    overflow: hidden !important;
}

.loaded {
    width: 60px;
    height: 60px;
    position: absolute;
    left: 50%;
    top: 50%;
    background-image: url(../images/preloading.gif);
    background-repeat: no-repeat;
    background-position: center;
    -moz-background-size: cover;
    background-size: cover;
    margin: -20px 0 0 -20px;
}
*/
a,
a:hover,
a:active,
a:focus {
    text-decoration: none;
    outline: none;
}

    a,
    button,
    a span {
        -webkit-transition: 0.2s ease-in-out;
        -o-transition: 0.2s ease-in-out;
        transition: 0.2s ease-in-out;
    }

.btn.extra-width {
    padding: 13px 40px;
}

.btn:focus,
.btn:active {
    outline: inherit;
}

*,
*:before,
*:after {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    /*    direction: rtl;*/
}

p {
    margin: 0 0 15px;
}

.clear {
    clear: both;
}

.blue {
    color: #337ab7;
}
/*ol,
ul {
    list-style: none;
    padding: 0;
}*/

small {
    color: inherit !important;
}

ul {
    list-style: none;
    padding: 0;
}

img {
    max-width: 100%;
}

textarea,
input[type="text"],
input[type="password"],
input[type="datetime"],
input[type="datetime-local"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="week"],
input[type="number"],
input[type="email"],
input[type="url"],
input[type="search"],
input[type="tel"],
input[type="color"],
.uneditable-input {
    outline: none;
}

.form-control {
    border: 1px solid rgba(0, 0, 0, 0.08);
    font-size: 16px;
    height: 45px;
}

    .form-control:focus {
        border-color: #82ca9c;
    }

h1,
h2,
h3 {
    /*font-family: 'Lane', sans-serif;*/
    font-weight: 300;
    margin: 0;
}

h4,
h5 {
    /*font-family: 'roboto', sans-serif;*/
    font-weight: 300;
    margin: 0;
    line-height: 2rem;
}

h1 {
    font-size: 3rem;
    line-height: 4rem;
    font-weight: 300;
}

h2 {
    font-size: 2.5rem;
    line-height: 3rem;
    margin-bottom: .9rem;
}

h3 {
    font-weight: 300;
    margin-bottom: .9rem;
    font-size: 2.1rem;
    line-height: 2.5rem;
}

h4 {
    font-size: 1.6rem;
    line-height: 2.2rem;
    margin-bottom: 1.1rem;
}

h5 {
    font-size: 1.3rem;
    margin-bottom: 1rem;
}

h6 {
    font-size: 1rem;
    margin-bottom: .9rem;
}

p {
    line-height: 1.5rem;
    font-size: 1.1rem;
}

    p:last-child {
        margin-bottom: 0px;
    }

.container {
    max-width: 1260px;
}

.col-md-10 {
    width: 55%;
    position: relative;
}

.col-md-offset-1 {
    margin-left: 22.5%;
}

section {
    padding-top: 40px;
    padding-bottom: 30px;
}

section.light-blue {
    background: #579ac4;
    color: white;
}

section.light-gray {
    background: #fafafa;
    color: #222;
}

.section-title {
    width: 210px;
    height: 37px;
    margin: 0px auto;
}

    .section-title h3 {
        float: left;
        margin-left: 15px;
        /* font-family: "robotolight";*/
    }

    .section-title i {
        color: #444;
        float: left;
        font-size: 28px;
        height: 30px;
        margin-top: 6px;
        width: 30px
    }

@media only screen and (max-width: 1200px) {
    .page-header {
        height: 350px;
        background-size: 100% 100%;
        background-position: 0px 0px;
    }

    .home-content {
        margin-top: 250px;
    }
}

@media only screen and (max-width: 600px) {
    .navbar-toggler{display:none;}
    .page-header { height: 250px; background-size: 100% 100%; background-position: 0px 0px; margin-top: 60px;}
    #language *{font-size:17px;}
    .col-md-10 {width: 70%;}
    .col-md-offset-1{margin-left: 5%; padding-top:.8rem;}
    .navbar-brand .aikido-text-box .aikido-text{ font-size:19px;}
    .head_title{padding-left:.5rem; padding-right:.5rem;}
    .head_title small{padding-left:3px;}
    .title i{margin-top:6px;}
    .section-title i{margin-top:6px;}
    .home-content { margin-top: 165px;}
    .home-content h1{font-size:17px !important;}
    .home-content p{font-size:15px !important;}
    .dojo-content { width: 100%; margin-top: 40px; padding-left: 3%;  padding-right: 3%; }
    .dojo-content:first-child{margin-top: 0px;}
    #seminars .section-title{width:100% !important; padding-left:.8rem;}
    .seminar-content{width: 100%; margin-top: 40px; padding-left: 3%;  padding-right: 3%; }
    .seminar-content:first-child{margin-top: 0px;}
    .seminar-content:last-child{ width: 100%;}
    .seminar-content .title { width: 100%;}
    #gallery .section-title{width:100% !important; padding-left:.8rem;}
    .d-carousel img{float:left; max-height:900px !important;}
    #footer .column-3 {width: 100%;margin-top: 20px;border-top: 1px solid #555;padding-top: 15px;}
    #footer .column-3:first-child{ margin-top: 0px; border-top: 0px;}
    #footer small {padding-left:2px;}
}



