.d-carousel {
    width: 100%;
    height: 700px !important;
    max-height: 700px !important;
}

    .d-carousel .carousel-item {
        height: 700px !important;
        max-height: 700px !important;
    }

