.is-mobile-device #dojo {
}

.is-mobile-device .head_title h3 {
    font-size: 1.6rem;
    line-height: 1.9rem;
}

.is-rtl.is-mobile-device .head_title h3 {
    font-size: 1.52rem;
}

.is-mobile-device .head_title h5 {
    font-size: 1.1rem;
    line-height: 1.2rem;
}

.is-mobile-device .sensei-foto {
    position: relative;
    left: 0px;
    top: 20px;
    height: auto;
}

.is-mobile-device #dojo-second {
    margin-top: 0px;
}

