.navbar {
    background: #2a2a2a;
    margin-bottom: 0px;
    border: 0px;
    border-radius: 0px;
    /*box-shadow: 0 0px 3px 0px #999;*/
    /*position: fixed;
    width: 100%;*/
}

.navbar-brand {
    position: relative;
    padding-top: 0px;
    height: 50px;
}

    /*.navbar-brand .aikido {
    background: url(../images/aikido-caligraphy.png);
    background-position: 0px 0px;
    background-size: 100% 200%;
    height: 40px;
    width: 145px;
    float: left;
    margin-top: 5px;
}

.navbar-brand:hover .aikido {
    background-position: 0px -40px;
}*/

    .navbar-brand .aikido-icon {
        float: left;
        height: 50px;
    }

    .navbar-brand .aikido-text-box {
        float: left;
        height: 50px;
        width: 85px;
    }

        .navbar-brand .aikido-text-box .aikido-text-icon {
            float: left;
            height: 25px;
            margin-left: 10px;
        }

        .navbar-brand .aikido-text-box .aikido-text {
            float: left;
            width: 100%;
            margin-left: 10px;
            color: #222;
        }

    .navbar-brand:hover .aikido-text {
        /*color: #579ac4;*/
    }

.navbar-default .navbar-header {
    padding: 9px;
}

    .navbar-default .navbar-header a {
        color: #e0e0e0;
        font-size: 25px;
    }

.navbar-default .navbar-collapse {
    height: 65px;
    margin-top: -1rem;
    margin-bottom: -1rem;
}

.navbar-default .navbar-collapse, .navbar-default .navbar-form {
    border-color: transparent;
}

.navbar-default .navbar-nav {
    height: 100%;
}

    .navbar-default .navbar-nav > li {
        height: 100%;
        /*    padding: 9px;*/
    }

        .navbar-default .navbar-nav > li > a {
            background-color: transparent !important;
            color: #606060;
            font-size: 17px;
            line-height: 62px;
            padding-left: 1.4rem;
            padding-right: 1.4rem;
            cursor: pointer;
        }

            .navbar-default .navbar-nav > li > a:hover, .navbar-default .navbar-nav > li > a:focus {
                color: #579ac4 !important;
            }

        .navbar-default .navbar-nav > li.active {
            background: #579ac4;
        }

            .navbar-default .navbar-nav > li.active > a {
                color: white !important;
            }


.navbar-toggle {
    background-color: transparent;
    border: 1px solid #e1e1e1;
    border-radius: 0;
    float: right;
    margin-bottom: 8px;
    margin-right: 20px;
    margin-top: 8px;
    padding: 9px 10px;
    position: relative;
}

.v-flag-icon {
    position:relative;
    top:1px;
}