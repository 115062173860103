.page-header {
    /*background: url(/images/tori.jpg);*/
/*    background-size: 100%;
    background-position: 0px -265px;*/
    height: 450px;
    margin-top: 65px;
    margin-bottom: 0px;
    padding-bottom: 0px;
}

.home-wrapper {
    width: 100%;
    direction: ltr;
}

.head_title {
    width: 100%;
}

.overlay-fluid-block {
    background-color: rgba(0, 0, 0, 0.10);
    width: 100%;
    height: 100%;
}

.home-content {
    margin-top: 260px;
    color: white;
}

.aiki-main {
    position: absolute;
    bottom: -10px;
    left: -100px;
    height: 250px;
}
