
.seminar-content {
    float: left;
    width: 34%;
    padding-left: 1%;
    /*font-family: "robotolight";*/
}

    .seminar-content:last-child {
        width: 29%;
    }

    .seminar-content .title {
        float: left;
        background: #efefef;
        margin-top: 10px;
        padding: 3px 10px 3px 10px;
        font-size: 18px;
    }

    .seminar-content .content-text {
        float: left;
        width: 100%;
        font-size: 18px;
    }
