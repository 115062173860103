#footer {
    margin-top: 80px;
    background: #2a2a2a;
    font-family: "robotolight" !important;
    /*    height:100px;*/
}

    #footer .column-3 {
        float: left;
        width: 33.33%;
        color: #bbb;
    }

.copyright {
    margin-top: 30px;
    color: #579ac4;
    width: 100%;
}
