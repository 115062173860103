/*.sensei {
    float: left;
    height: 120px;
    width: 110px;*/
   /* background: url(/images/sensei.png);*/
    /*background-position: -27px 0px;
    border-radius: 70px;
}*/

.sensei-desc {
    float: left;
    margin-left: 40px;
    font-size: 20px;
}

.sensei-foto {
    position:absolute;
    left:0px;
    top:-130px;
    height:350px;
}

#dojo-second {
    margin-top:190px;
}

.dojo-content {
    float: left;
    /*width: 47%;*/
    width: 50%;
    padding-left: 1.5%;
    padding-right: 1.5%;
}

    .dojo-content i {
        float: left;
        font-size: 26px;
        width: 30px;
        height: 30px;
        color: #444;
        margin-top: 6px;
    }

    .dojo-content h3 {
        float: left;
        margin-left: 6px;
        color: #444;
        text-transform: uppercase;
        /* font-family: "robotolight";*/
    }

    .dojo-content .content-text {
        float: left;
        width: 100%;
        margin-top: 10px;
        font-size: 21px;
        /*font-family: "robotolight";*/
    }

.dojo-devider {
    float: left;
    width: 100%;
    height: 50px;
}

table.schedule {
    width: 100%;
}

    table.schedule tr {
        border-bottom: 1px solid #888;
    }

        table.schedule tr:last-child {
            border: 0px;
        }

    table.schedule th {
        font-family: "robotolight";
        font-weight: normal;
        color: #337ab7;
        padding: 1px 10px 4px 10px;
        border-right: 1px solid #888;
    }

        table.schedule th:last-child {
            border: 0px;
        }

    table.schedule td {
        border-right: 1px solid #888;
        padding: 4px 10px 4px 10px;
    }

        table.schedule td:last-child {
            border: 0px;
        }
