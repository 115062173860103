html.is-rtl {direction:rtl;}

.is-rtl h3 {direction: rtl;}

.is-rtl .navbar-brand .aikido-icon {float:right; transform: scaleX(-1);}
.is-rtl .navbar-brand .aikido-text-box {float:right;}
.is-rtl .navbar-brand .aikido-text-box .aikido-text-icon{float:right; margin-left:auto; margin-right:10px;}
.is-rtl .navbar-brand .aikido-text-box .aikido-text{float:right; text-align:right; margin-left:auto; margin-right:10px; font-size:20px;}

.is-rtl #language .dropdown-item{float:right; direction:rtl; text-align:right;}

.is-rtl .dojo-content{float:right;}
.is-rtl .dojo-content i{float:right;}
.is-rtl .dojo-content h3 {float:right; margin-left:auto; margin-right:6px;}
.is-rtl .dojo-content .content-text {text-align:right; font-size:20px;}

.is-rtl #seminars .row {direction:rtl;}
.is-rtl .seminar-content {float:right;}
.is-rtl .seminar-content .title {float:right; text-align:right;}
.is-rtl .seminar-content .content-text{ text-align:right;}

.is-rtl .section-title h3{ text-align:right; float:right; margin-left: auto; margin-right: 15px;}
.is-rtl .section-title i {float:right;}

.is-rtl #footer .column-3{float:right; direction:rtl; text-align:right;}
.is-rtl #footer .column-3.sensei-dan div{float:right; direction:rtl;}

    .is-rtl .copyright {
        text-align: right;
    }

@media only screen and (max-width: 600px) {
    .is-rtl .navbar-brand{margin-right:0px;}
    .is-rtl .head_title small{padding-left:0px; padding-right:3px;}
    .is-rtl  #seminars .section-title{padding-left:0px; padding-right:.8rem; }
    .is-rtl #gallery .section-title {padding-left: 0px; padding-right: .8rem;}
    .is-rtl #footer small {padding-left:0px; padding-right:2px;}
}
